<template>
  <div>
    <a-card title="收支报表">
      <a-row gutter="24">
        <a-col :span="10">
          <a-form-model-item :wrapper-col="{ span: 24 }">
            <a-radio-group v-model="radioValue" button-style="solid" @change="changeRadio" style="width: 100%;">
              <a-radio-button value="0" style="width: 25%;">今日</a-radio-button>
              <a-radio-button value="1" style="width: 25%;">昨天</a-radio-button>
              <a-radio-button value="-6" style="width: 25%;">近7天</a-radio-button>
              <a-radio-button value="-29" style="width: 25%;">近30天</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="自定义时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-range-picker v-model="searchForm.dateRange" @change="changeDate" style="width: 100%;" />
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-descriptions :column="{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }" style="text-align: center;">
        <a-descriptions-item label="收入金额"><span class="total">{{ summary.income.toFixed(2) }}</span></a-descriptions-item>
        <a-descriptions-item label="支出金额"><span class="total">{{ summary.express.toFixed(2) }}</span></a-descriptions-item>
        <a-descriptions-item label="利润"><span class="total">{{ summary.profit.toFixed(2)
            }}元</span></a-descriptions-item>
      </a-descriptions>

      <div>
        <!-- <flow-pane v-show="currentTab === 'flow'" :items="currentPageItems" :loading="loading" :pagination="pagination"
            @tableChange="tableChange" :statusItems="statusItems" /> -->

        <a-table :columns="columns" :data-source="currentPageItems" size="small" :pagination="pagination"
          @change="tableChange" :loading="loading">
        </a-table>

      </div>
      <div style="text-align: center; margin-top: 12px;">
        <a-spin :spinning="loading && searchForm.page > 1" />
      </div>
    </a-card>
  </div>
</template>

<script>
// import { productionReportDetialsList } from '@/api/report'
import { DailyDetail } from '@/api/finance'
import NP from 'number-precision'
import moment from 'moment'

export default {
  name: 'ProductReport',
  components: {
    // FlowPane: () => import('./flowPane'),
    // GoodsPane: () => import('./goodsPane'),
  },
  data() {
    return {
      NP,
      radioValue: '0',
      summary: {
        express: 0,
        income: 0,
        profit: 0,
      },
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "编号",
          dataIndex: "number",
        },
        {
          title: "收支类型",
          dataIndex: "type_display",
        },
        {
          title: "客户",
          dataIndex: "client_name",
        },
        {
          title: "供应商",
          dataIndex: "supplier_name",
        },
        {
          title: "收支项目",
          dataIndex: "charge_item_name",
        },
        {
          title: "结算账户",
          dataIndex: "account_name",
        },
        {
          title: "应收/付金额",
          dataIndex: "total_amount",
        },
        // {
        //   title: '实收/付金额',
        //   dataIndex: 'charge_amount'
        // },
        {
          title: "经手人",
          dataIndex: "handler_name",
        },
        {
          title: "处理时间",
          dataIndex: "handle_time",
        },
        {
          title: "时间段",
          ellipsis: true,
          customRender: (text, row, index) => {
            const { start_date, end_date } = row;
            // 使用三元运算符检查日期是否存在，如果不存在则显示“未设置”
            const formattedStartDate = start_date
              ? start_date
              : "";
            const formattedEndDate = end_date
              ? start_date
              : "";

            // 如果两个日期都为“未设置”，可以选择显示不同的文本
            if (
              formattedStartDate === "" &&
              formattedEndDate === ""
            ) {
              return "";
            }
            return `${formattedStartDate} 至 ${formattedEndDate}`;
          },
        },

        {
          title: "备注",
          dataIndex: "remark",
          width: "200px",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: "156px",
        },
      ],
      searchForm: {
        dateRange: [moment().startOf('day'), moment().startOf('day')],
        statues: null,
        page: 1,
        page_size: 16
      },
      currentPageItems: [],  // 当前页的数据
      loading: true,
      // categoryItems: [],
      total: {
        quantity: 0,
        cost: 0,
        amount: 0,
      },
      items: [],
      pagination: { current: 1, total: 0, pageSize: 16 },
    };
  },
  // computed: {

  // },
  methods: {
    initailize() {
      if (this.$route.query.type) {
        this.currentTab = this.$route.query.type;
      } else {
        this.$router.replace({ query: { type: this.currentTab } })
      }
      this.list();
      
    },
    resetSummary() {
      // 重置 summary 对象的所有属性为 0
      this.radioValue = null;
      this.summary = {
        express: 0,
        income: 0,
        profit: 0,
      };
    },
    calculateSummary() {
      const result = this.items.reduce((acc, current) => {
      const amount = parseFloat(current.total_amount);
      if (current.type === 'income') {
        acc.income += amount;
      } else if (current.type === 'expenditure') {
        acc.express += amount;
      }
      return acc;
    }, { income: 0, express: 0 });

    // console.log('收入总额:', result.income);
    // console.log('支出总额:', result.express);

    this.summary.income = result.income;
    this.summary.express = result.express;
    this.summary.profit = this.summary.income- this.summary.express;
    },

    list() {
      let form = {
        start_date: this.searchForm.dateRange.length > 0 ? this.searchForm.dateRange[0].format('YYYY-MM-DD') : null,
        end_date: this.searchForm.dateRange.length > 0 ? this.searchForm.dateRange[1].format('YYYY-MM-DD') : null,
        fee_type: this.searchForm.fee_type
      };
      if (!form.end_date) {
        form.end_date = moment(form.status_date_before).add(1, 'days').format('YYYY-MM-DD');
      }

      this.loading = true;
      // salesReportStatistics(form).then(resp => {
      //   this.total = resp;
      // })
      console.log('form', form)
      DailyDetail(form).then(resp => {
        this.items = resp.results;
        this.resetSummary()
        this.calculateSummary()
        this.pagination.total = resp.count;
        console.log(this.items.count)
        this.setPageData(1);  // 设置初始页数据
      }).finally(() => {
        this.loading = false;
      });
    },

    setPageData(page) {
      const pageSize = 16;  // 你可以将这个设置为一个响应式的数据属性
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      this.currentPageItems = this.items.slice(startIndex, endIndex);
      this.pagination.current = page;
    },

    tableChange(pagination, filters, sorter) {
      this.sortKey = sorter.field;
      this.sortOrder = sorter.order || 'asc'; // 'ascend' 或 'descend'，这里假设 Ant Design Vue 的排序标记
      this.sortData();
      this.setPageData(pagination.current);
    },

    sortData() {
    if (!this.sortKey) return; // 如果没有指定排序字段，则不执行排序

    this.items.sort((a, b) => {
      const isAsc = this.sortOrder === 'ascend';
      if (a[this.sortKey] < b[this.sortKey]) return isAsc ? -1 : 1;
      if (a[this.sortKey] > b[this.sortKey]) return isAsc ? 1 : -1;
      return 0;
    });
  },

    search() {
      this.list();
    },
   
    changeRadio(evt) {
      let value = evt.target.value;
      if (value < 0) {
        this.searchForm.dateRange = [moment().startOf('day').add(value, 'day'), moment().startOf('day')]
      } else {
        this.searchForm.dateRange = [moment().startOf('day').subtract(value, 'day'), moment().startOf('day').subtract(value, 'day'),]
      }
      this.search();
    },
    changeDate() {
      this.radioValue = null;
      this.search();
    },
    changeCategory() {
      this.search();
    },
    resetForm() {
      this.radioValue = '0';
      this.searchForm = {
        dateRange: [moment().startOf('day'), moment().startOf('day')],
        category: null,
        page: 1,
        page_size: 16
      };
    },
  },
  mounted() {
    const { start_date, end_date,fee_type } = this.$route.query;
    console.log( this.$route.query);
    console.log(start_date, end_date);
    if (start_date && end_date) {
      this.searchForm.fee_type = fee_type
      this.searchForm.dateRange = [moment(start_date), moment(end_date)];
    }
    this.initailize();
  },
}
</script>

<style scoped>
.total {
  color: #a94442;
}
</style>